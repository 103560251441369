<template>
  <div class="panel-container">
    <!-- 제목 -->
    <div class="card-title">
      <div class="flex-between">
        <div class="subtitle4 main">{{ card.name }}</div>
        <button class="button is-gray" style="width:56px" @click="clickDelete">삭제</button>
      </div>
      <div class="flex-align" style="margin-top:8px">
        <div class="tag-temp body6-medium">임시저장</div>
        <div class="body5 sub2" style="margin-left:8px">{{ card.key }}.launchpack.co.kr</div>
      </div>
    </div>
    <!-- 내용 -->
    <div>
      <div class="body4-bold sub2" style="margin-bottom:4px">서비스 도메인</div>
      <div class="body6 sub4">서비스 개설 후 도메인 연결이 가능합니다.</div>

      <div>
        <div>
          <div class="body4-bold sub2" style="margin:16px 0 8px 0">서비스팩</div>
          <dr-temporay :list="card.conversions" :visibleDelete="false"
                       @delete="item=>deleteItem('conversions',item)"></dr-temporay>
        </div>
        <div>
          <div class="body4-bold sub2" style="margin:16px 0 8px 0">플러그인</div>
          <dr-temporay :list="card.plugins"
                       @add="clickEdit(2)" :visibleDelete="false"
                       @delete="item=>deleteItem('plugins',item)"></dr-temporay>
        </div>
      </div>
    </div>
    <!-- 하단 -->
    <div class="body2-medium main" style="margin-top:24px"><span class="price h6">{{ card.price|currencyNum }}</span>원</div>
    <div class="flex-between" style="margin-top:16px">
      <a :href="`https://sbpreview.launchpack.co.kr/super_builder/preview?key=${card.key}&id=${card.id}&device=mobile&package=${this.card.conversions[0].key}`"
         target="_blank" style="width:50%;margin-right:6px">
        <button class="button body4-medium is-primary-light" style="width:100%;">미리보기</button>
      </a>
      <button class="button body4-medium is-primary" style="width:50%;margin-left:6px" @click="$emit('create')">개설하기</button>
    </div>
  </div>
</template>

<script>
  import DrTemporay from "./DrTemporay";
  export default {
    name: "CardTemporary",
    components: {DrTemporay},
    created() {

    },
    props: {
      card: {
        type: Object
      }
    },
    data() {
      return {

      }
    },
    methods: {
      clickDelete() {
        this.$emit('delete');
      },
      clickEdit(step) {
        this.$emit('edit', step);
      },
      openPreview() {
        let url = `https://sbpreview.launchpack.co.kr/super_builder/preview?key=${this.card.key}&id=${this.card.id}&device=mobile`;
        window.open(url, '_blank', 'width=434, height=736, top=50, left=100, fullscreen=no, ' +
          'menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      },
      deleteItem(type, item) {
        this.$emit('deleteItem', {
          service: this.card,
          type: type,
          item: item
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .panel-container
    margin-top 20px

  .card-title
    padding-bottom 16px
    border-bottom 1px solid $gray2
    margin-bottom 16px

  .tag-temp
    border-radius 4px
    background-color $primary-light2
    color $primary
    padding 3px 8px

  .button-edit
    width 120px
    margin-left 16px

  .button-primary
    width 200px
    margin-left 16px

  .icon-device
    filter invert(54%) sepia(46%) saturate(5884%) hue-rotate(360deg) brightness(100%) contrast(110%)
    margin-right 8px

</style>
